@use 'functions';
@use 'mixins';
@use 'fonts' as fonts;
@use 'icons';

@use 'datetimepicker';
@use 'keyframes';

@use 'media_queries' as mq;

@use 'src/styles/_var' as vars;
@use 'src/styles/_color_themes' as colors;
@use 'src/styles/_urls' as urls;

@use 'modals' as modals;

$px: vars.$px;

html,
body {
  line-height: normal;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
  outline: 0;
  min-height: 100vh;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  font-family: fonts.$font-name, sans-serif;
  font-size: vars.$root-font-size-px;
  background: var(--bg);
  color: var(--text-primary);
  accent-color: var(--accent-blue-100);

  .grecaptcha-badge {
    display: none !important;
  }

  @media #{mq.$mq-medium} {
    // font-size: mq.$base-font-size*1.2;
  }

  @media #{mq.$mq-large} {
    // font-size: mq.$base-font-size*1.3;
  }

  @media #{mq.$mq-xlarge} {
    // font-size: mq.$base-font-size*1.4;
  }

  @media #{mq.$mq-xxlarge} {
    // font-size: mq.$base-font-size*1.6;
  }

  @media #{mq.$mq-retina} {
  }
}

@mixin h0 {
  @include mixins.typography(bold, 40 * $px, 48 * $px);
}

@mixin h1 {
  @include mixins.typography(600, 24 * $px, 32 * $px);
}

@mixin h2 {
  @include mixins.typography(600, 20 * $px, 28 * $px);
}

@mixin h3 {
  @include mixins.typography(600, 18 * $px, 24 * $px);
}

@mixin h4 {
  @include mixins.typography(600, 16 * $px, 24 * $px);
}

@mixin h5 {
  @include mixins.typography(600, 14 * $px, 20 * $px);
}

@mixin paragraph {
  @include mixins.typography(400, 14 * $px, 20 * $px);
}

@mixin small {
  @include mixins.typography(normal, 12 * $px, 16 * $px);
}

@mixin small-bold {
  @include mixins.typography(600, 12 * $px, 16 * $px);
}

@mixin description {
  @include mixins.typography(normal, 10 * $px, 16 * $px);
}

@mixin h3-lite {
  @include mixins.typography(400, 18 * $px, 24 * $px);
}

// this one is not defined in design spec but is used in split button as button
@mixin button-text {
  @include mixins.typography(bold, 14 * $px, 18 * $px);
  display: flex;
  align-items: center;
  letter-spacing: 0.2 * $px;
  cursor: pointer;
}

@mixin large-button-text {
  @include mixins.typography(bold, 14 * $px, 20 * $px);
  display: flex;
  align-items: center;
  letter-spacing: 0.2 * $px;
  cursor: pointer;
}

@mixin label {
  @include mixins.typography(bold, 12 * $px, 16 * $px);
  letter-spacing: 1 * $px;
  text-transform: uppercase;
}

@mixin title {
  @include mixins.typography(bold, 12 * $px, 16 * $px);
  letter-spacing: 1 * $px;
  text-transform: uppercase;
}

@mixin input-text {
  @include mixins.typography(600, 14 * $px, 20 * $px);
}

@mixin link-text {
  @include mixins.typography(normal, 12 * $px, 16 * $px);
  text-decoration-line: underline;
  cursor: pointer;
}

@mixin large-link-text {
  @include mixins.typography(600, 14 * $px, 20 * $px);
  text-decoration-line: underline;
  cursor: pointer;
}

@mixin title-bar-text {
  // design one looks too small
  // @include mixins.typography(500, 10 * $px, 14 * $px);
  @include mixins.typography(500, 12 * $px, 14 * $px);
}

.h0 {
  @include h0();
}

.h1 {
  @include h1();
}

.h2 {
  @include h2();
}

.h3 {
  @include h3();
}

.h4 {
  @include h4();
}

.h5 {
  @include h5();
}

.paragraph {
  @include paragraph();
}

.small {
  @include small();
}

.small-bold {
  @include small-bold();
}

.description {
  @include description();
}

// this one is not defined in design spec but is used in split button as button
.button-text {
  @include button-text();
}

.large-button-text {
  @include large-button-text();
}

.label {
  @include label();
}

.title {
  @include title();
}

.input-text {
  @include input-text();
}

.link-text {
  @include link-text();
}

.large-link-text {
  @include large-link-text();
}

.title-bar-text {
  @include title-bar-text();
}

.h3-lite {
  @include h3-lite();
}

::selection {
  background-color: var(--accent-blue-80);
  color: var(--text-primary);
}

input::placeholder {
  color: var(--tertiary);
  font-family: mixins.$font-name;
}

.hide {
  display: none;
}

.noselect {
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

// loader.gif
.dotted-loader {
  background: var(--dotted-loader);
  width: 88 * $px;
  height: 88 * $px;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  border: 2 * $px solid #0c0c10;
  background-color: #0c0c10;
}

// scrollbars
// ::-webkit-scrollbar {
//   /* Remove scrollbar space */
//   width: 0;
//   height: 0;
//   /* Optional: just make scrollbar invisible */
//   background: transparent;
// }

// ::-webkit-scrollbar {
//   border-radius: 2px;
//   width: 8px;
// }

// ::-webkit-scrollbar-thumb {
//   background: #474A54;
//   border-radius: 16px;
//   box-shadow: inset 2px 2px 2px hsl(0deg 0% 100% / 25%), inset -2px -2px 2px rgb(0 0 0 / 25%);
// }

// ::-webkit-scrollbar-track {
//   // background: linear-gradient(90deg,#434343,#434343 1px,#111 0,#111);
//   background: transparent;
// }

.pagination {
  display: flex;
  margin-top: 16 * $px;
  gap:24*$px;
  padding: 8 * $px 16 * $px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border-bottom: 1*$px solid var(--stroke-default);
  background: var(--tertiary);
  width: 100%;
}
