@use 'variables' as vars;

$themes: (
  'dark': (
    primary: vars.$primary-color-dark,
    secondary: vars.$secondary-color-dark,
    tertiary: vars.$tertiary-color-dark,
    negative: vars.$negative-color-dark,
    text-primary: vars.$text-primary-color-dark,
    text-secondary: vars.$text-secondary-color-dark,
    text-tertiary: vars.$text-tertiary-color-dark,
    text-negative: vars.$text-primary-color-light,
    icons-primary: vars.$icons-primary-color-dark,
    icons-secondary: vars.$icons-secondary-color-dark,
    icons-tertiary: vars.$icons-tertiary-color-dark,
    icons-active: vars.$icons-active-color-dark,
    stroke-default: vars.$stroke-default-color-dark,
    button-filled-bg-default: vars.$button-filled-bg-default-color-dark,
    button-filled-bg-hover-pressed: vars.$button-filled-bg-hover-pressed-color-dark,
    button-filled-bg-disabled: vars.$button-filled-bg-disabled-color-dark,
    button-filled-text-default: vars.$button-filled-text-default-color-dark,
    button-filled-text-disabled: vars.$button-filled-text-disabled-color-dark,
    message-text: vars.$message-text-color-dark,
    gradients-pink: vars.$gradients-pink-color-dark,
    message-bg: vars.$message-bg-color-dark,
    message-neutral: vars.$message-neutral-color-dark,
    message-success: vars.$message-success-color-dark,
    message-warning: vars.$message-warning-color-dark,
    message-error: vars.$message-error-color-dark,
    message-info: vars.$message-info-color-dark,
    stroke-hover-active: vars.$stroke-hover-active-color-dark,
    stroke-accent: vars.$stroke-accent-color-dark,
    stroke-selected-002: vars.$stroke-selected-002-dark,
    gradients-ramp-blue: vars.$gradients-ramp-blue-color-dark,
    button-outlined-bg-default: vars.$button-outlined-bg-default-color-dark,
    button-outlined-bg-hover-pressed: vars.$button-outlined-bg-hover-pressed-color-dark,
    button-outlined-bg-disabled: vars.$button-outlined-bg-disabled-color-dark,
    button-outlined-text-default: vars.$button-outlined-text-default-color-dark,
    button-outlined-text-hover-pressed: vars.$button-outlined-text-hover-pressed-color-dark,
    button-outlined-text-disabled: vars.$button-outlined-text-disabled-color-dark,
    button-outlined-bg-standard: vars.$button-outlined-bg-standard-color-dark,
    gradient-pink: vars.$gradient-pink-dark,
    accents-brand-azure-000: vars.$accents-brand-azure-000-dark,
    accents-brand-azure-500: vars.$accents-brand-azure-500-dark,
    accents-brand-gold-000: vars.$accents-brand-gold-000-dark,
    accents-brand-gold-500: vars.$accents-brand-gold-500-dark,
    white-primary: vars.$white-primary-dark,
    stroke-selected-001: vars.$stroke-selected-001-dark,
    further: vars.$further-dark,
  ),
  'light': (
    primary: vars.$primary-color-light,
    secondary: vars.$secondary-color-light,
    tertiary: vars.$tertiary-color-light,
    negative: vars.$negative-color-light,
    text-primary: vars.$text-primary-color-light,
    text-secondary: vars.$text-secondary-color-light,
    text-tertiary: vars.$text-tertiary-color-light,
    text-negative: vars.$text-primary-color-dark,
    icons-primary: vars.$icons-primary-color-light,
    icons-secondary: vars.$icons-secondary-color-light,
    icons-tertiary: vars.$icons-tertiary-color-light,
    icons-active: vars.$icons-active-color-light,
    stroke-default: vars.$stroke-default-color-light,
    button-filled-bg-default: vars.$button-filled-bg-default-color-light,
    button-filled-bg-hover-pressed: vars.$button-filled-bg-hover-pressed-color-light,
    button-filled-bg-disabled: vars.$button-filled-bg-disabled-color-light,
    button-filled-text-default: vars.$button-filled-text-default-color-light,
    button-filled-text-disabled: vars.$button-filled-text-disabled-color-light,
    message-text: vars.$message-text-color-light,
    gradients-pink: vars.$gradients-pink-color-light,
    message-bg: vars.$message-bg-color-light,
    message-neutral: vars.$message-neutral-color-light,
    message-success: vars.$message-success-color-light,
    message-warning: vars.$message-warning-color-light,
    message-error: vars.$message-error-color-light,
    message-info: vars.$message-info-color-light,
    stroke-hover-active: vars.$stroke-hover-active-color-light,
    stroke-accent: vars.$stroke-accent-color-light,
    stroke-selected-002: vars.$stroke-selected-002-light,
    gradients-ramp-blue: vars.$gradients-ramp-blue-color-light,
    button-outlined-bg-default: vars.$button-outlined-bg-default-color-light,
    button-outlined-bg-hover-pressed: vars.$button-outlined-bg-hover-pressed-color-light,
    button-outlined-bg-disabled: vars.$button-outlined-bg-disabled-color-light,
    button-outlined-text-default: vars.$button-outlined-text-default-color-light,
    button-outlined-text-hover-pressed: vars.$button-outlined-text-hover-pressed-color-light,
    button-outlined-text-disabled: vars.$button-outlined-text-disabled-color-light,
    button-outlined-bg-standard: vars.$button-outlined-bg-standard-color-light,
    gradient-pink: vars.$gradient-pink-light,
    accents-brand-azure-000: vars.$accents-brand-azure-000-light,
    accents-brand-azure-500: vars.$accents-brand-azure-500-light,
    accents-brand-gold-000: vars.$accents-brand-gold-000-light,
    accents-brand-gold-500: vars.$accents-brand-gold-500-light,
    white-primary: vars.$white-primary-light,
    stroke-selected-001: vars.$stroke-selected-001-light,
    further: vars.$further-light,
  ),
);

@each $theme-name, $theme-colors in $themes {
  .theme.#{$theme-name} {
    @each $name, $color in $theme-colors {
      --#{$name}: #{$color};
    }
  }
}

.theme.dark {
  .inverted {
    @each $name, $color in map-get($themes, light) {
      --#{$name}: #{$color};
    }
  }
}

.theme.light {
  .inverted {
    @each $name, $color in map-get($themes, dark) {
      --#{$name}: #{$color};
    }
  }
}
