@use '../variables' as vars;

$px: vars.$px;

@mixin scrollbar(){
  &::-webkit-scrollbar {
      cursor: pointer;
      width: 8 * $px;
      height: 18 * $px;
  }

  &::-webkit-scrollbar-thumb {
      height: 18 * $px;
      border: 4 * $px solid var(--text-tertiary);
      background-clip: padding-box;
      border-radius: 16px;
      background-color: var(--text-tertiary);
  }

  &::-webkit-scrollbar-button {
      width: 0;
      height: 0;
      display: none;
  }

  &::-webkit-scrollbar-corner,
  &::-webkit-resizer {
    background: transparent;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }
}


.scrollbar-y {
  overflow-y: auto;
  @include scrollbar();
}

.scrollbar-x {
  overflow-x: auto;
  @include scrollbar();
}

.scrollbar {
  overflow: auto;
  @include scrollbar();
}


