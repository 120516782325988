@use '../variables' as vars;
@use '../fonts' as fonts;

$px: vars.$px;
$content-font: fonts.$content-font;


.cree8-table-container {
  border: 1px solid var(--stroke-default);
  border-radius: 8px;
  overflow: hidden;
  width: 100%;
}

.cree8-table {
  width:100%;
  border-collapse: collapse;
  border-radius: 8px;
  overflow: hidden;

  thead {
    background-color: var(--tertiary);
    th {
        color: var(--text-secondary);
        padding: 8 * $px 16 * $px;
        text-align: left;
        font-size: 12 * $px;
        font-family: $content-font;
        font-weight: 400;
        line-height: 20 * $px;
    }
  }

  tbody {
    background-color: var(--secondary);

    td {
      color:var(--text-primary);
      padding: 8 * $px 16 * $px;
      text-align: left;
      font-size: 14 * $px;
      font-family: $content-font;
      font-weight: 400;
      line-height: 20 * $px;

      &:hover {
        background-color: var(--primary);
      }

      .email-container {
        display: inline-block;
        position: relative;
        max-width: 180 * $px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        vertical-align: middle;
      }

    }

    tr:hover {
      cursor: pointer;
      background-color: var(--primary);
    }
  }
}
