@use 'variables' as vars;
@use 'fonts' as fonts;

$px: vars.$px;
$content-font: fonts.$content-font;
$brand-font: fonts.$brand-font;

// Base typography styles
$base-typography: (
  color: var(--text-primary)
);

.font-inter {
    font-family: $content-font;
}

.font-space-grotesk {
    font-family: $brand-font;
}

// Mixin to create typography classes
@mixin create-typography-class($name, $overrides) {
  .#{$name} {
    @each $property, $value in $base-typography {
      #{$property}: $value;
    }

    @each $property, $value in $overrides {
      #{$property}: $value;
    }
  }
}

// Display
@include create-typography-class('display1', (font-size: 64 * $px, font-family: $brand-font, font-weight: 700, text-transform: uppercase, line-height: 64 * $px));
@include create-typography-class('display2', (font-size: 48 * $px, font-family: $brand-font, font-weight: 700, text-transform: uppercase, line-height: 48 * $px));
@include create-typography-class('display3', (font-size: 32 * $px, font-family: $brand-font, font-weight: 700, text-transform: uppercase, line-height: 40 * $px));
@include create-typography-class('display4', (font-size: 24 * $px, font-family: $brand-font, font-weight: 700, text-transform: uppercase, line-height: 32 * $px));

//Headline
@include create-typography-class('headline1', (font-size: 24 * $px, font-family: $content-font, font-weight: 600, line-height: 32 * $px));
@include create-typography-class('headline2', (font-size: 20 * $px, font-family: $content-font, font-weight: 600, line-height: 24 * $px));
@include create-typography-class('headline3', (font-size: 18 * $px, font-family: $content-font, font-weight: 600, line-height: 24 * $px));
@include create-typography-class('headline4', (font-size: 16 * $px, font-family: $content-font, font-weight: 600, line-height: 24 * $px));
@include create-typography-class('headline5', (font-size: 14 * $px, font-family: $content-font, font-weight: 600, line-height: 24 * $px));


//Body
@include create-typography-class('body1', (font-size: 20 * $px, font-family: $content-font, font-weight: 400, line-height: 32 * $px));
@include create-typography-class('body2', (font-size: 16 * $px, font-family: $content-font, font-weight: 400, line-height: 24 * $px));
@include create-typography-class('body3', (font-size: 14 * $px, font-family: $content-font, font-weight: 400, line-height: 20 * $px));

//Other
@include create-typography-class('captions', (font-size: 12 * $px, font-family: $content-font, font-weight: 400, line-height: 20 * $px));
@include create-typography-class('input-sm', (font-size: 14 * $px, font-family: $content-font, font-weight: 400, line-height: 16 * $px));
@include create-typography-class('cta', (font-size: 14 * $px, font-family: $content-font, font-weight: 500, line-height: 16 * $px));


@include create-typography-class('headline-medium', (font-size: 18 * $px, font-family: $content-font, font-weight: 600, line-height: 24 * $px));
@include create-typography-class('subhead-small', (font-size: 14 * $px, font-family: $content-font, font-weight: 600, line-height: 24 * $px));
@include create-typography-class('caption-small', (font-size: 12 * $px, font-family: $content-font, font-weight: 400, line-height: 20 * $px));
