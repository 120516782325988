@keyframes _shake {
  0% {
    transform: rotate(-5deg);
  }
  100% {
    transform: rotate(5deg);
  }
}

@keyframes _shake-2x {
  0% {
    transform: rotate(-10deg);
  }
  100% {
    transform: rotate(10deg);
  }
}

@keyframes _blink {
  0% {
    transform: scale(0.8);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

// edited from https://codepen.io/nzbin/pen/GGrXbp - MIT
@mixin dotPulse($suffix: random($limit: 1000), $three-dots-size: 10px, $three-dots-color: 'rgba(99, 99, 224, 1)') {
  .dot-pulse {
    position: relative;
    left: -9999px;
    width: #{$three-dots-size};
    height: #{$three-dots-size};
    border-radius: calc(#{$three-dots-size} / 2);
    background-color: #{$three-dots-color};
    color: #{$three-dots-color};
    box-shadow: 9999px 0 0 calc(#{$three-dots-size} * -0.5) #{$three-dots-color};
    animation: #{dotPulse}-#{$suffix} 1.5s infinite linear;
    animation-delay: 0.25s;
  }

  .dot-pulse::before,
  .dot-pulse::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
    width: #{$three-dots-size};
    height: #{$three-dots-size};
    border-radius: calc(#{$three-dots-size} / 2);
    background-color: #{$three-dots-color};
    color: #{$three-dots-color};
  }

  .dot-pulse::before {
    box-shadow: calc(9999px - #{$three-dots-size} * 1.5) 0 0 calc(#{$three-dots-size} * -0.5) #{$three-dots-color};
    animation: #{dotPulseBefore}-#{$suffix} 1.5s infinite linear;
    animation-delay: 0s;
  }

  .dot-pulse::after {
    box-shadow: calc(9999px + #{$three-dots-size} * 1.5) 0 0 calc(#{$three-dots-size} * -0.5) #{$three-dots-color};
    animation: #{dotPulseAfter}-#{$suffix} 1.5s infinite linear;
    animation-delay: 0.5s;
  }

  @keyframes #{dotPulseBefore}-#{$suffix} {
    0% {
      box-shadow: calc(9999px - #{$three-dots-size} * 1.5) 0 0 calc(#{$three-dots-size} * -0.5) #{$three-dots-color};
    }
    30% {
      box-shadow: calc(9999px - #{$three-dots-size} * 1.5) 0 0 calc(#{$three-dots-size} * 0.2) #{$three-dots-color};
    }
    60%,
    100% {
      box-shadow: calc(9999px - #{$three-dots-size} * 1.5) 0 0 calc(#{$three-dots-size} * -0.5) #{$three-dots-color};
    }
  }

  @keyframes #{dotPulse}-#{$suffix} {
    0% {
      box-shadow: 9999px 0 0 calc(#{$three-dots-size} * -0.5) #{$three-dots-color};
    }
    30% {
      box-shadow: 9999px 0 0 calc(#{$three-dots-size} * 0.2) #{$three-dots-color};
    }
    60%,
    100% {
      box-shadow: 9999px 0 0 calc(#{$three-dots-size} * -0.5) #{$three-dots-color};
    }
  }

  @keyframes #{dotPulseAfter}-#{$suffix} {
    0% {
      box-shadow: calc(9999px + #{$three-dots-size} * 1.5) 0 0 calc(#{$three-dots-size} * -0.5) #{$three-dots-color};
    }
    30% {
      box-shadow: calc(9999px + #{$three-dots-size} * 1.5) 0 0 calc(#{$three-dots-size} * 0.2) #{$three-dots-color};
    }
    60%,
    100% {
      box-shadow: calc(9999px + #{$three-dots-size} * 1.5) 0 0 calc(#{$three-dots-size} * -0.5) #{$three-dots-color};
    }
  }
}

@mixin dotElastic($suffix: random($limit: 1000), $three-dots-size: 10px, $three-dots-color: '#ACCC59') {
  .dot-elastic {
    position: relative;
    width: #{$three-dots-size};
    height: #{$three-dots-size};
    border-radius: calc(#{$three-dots-size} / 2);
    background-color: #{$three-dots-color};
    color: #{$three-dots-color};
    animation: #{dotElastic}-#{$suffix} 1s infinite linear;
  }

  .dot-elastic::before,
  .dot-elastic::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
  }

  .dot-elastic::before {
    left: calc(#{$three-dots-size} * -1.5);
    width: #{$three-dots-size};
    height: #{$three-dots-size};
    border-radius: calc(#{$three-dots-size} / 2);
    background-color: #{$three-dots-color};
    color: #{$three-dots-color};
    animation: #{dotElasticBefore}-#{$suffix} 1s infinite linear;
  }

  .dot-elastic::after {
    left: calc(#{$three-dots-size} * 1.5);
    width: #{$three-dots-size};
    height: #{$three-dots-size};
    border-radius: calc(#{$three-dots-size} / 2);
    background-color: #{$three-dots-color};
    color: #{$three-dots-color};
    animation: #{dotElasticAfter}-#{$suffix} 1s infinite linear;
  }

  @keyframes #{dotElasticBefore}-#{$suffix} {
    0% {
      transform: scale(1, 1);
    }
    25% {
      transform: scale(1, 1.5);
    }
    50% {
      transform: scale(1, 0.67);
    }
    75% {
      transform: scale(1, 1);
    }
    100% {
      transform: scale(1, 1);
    }
  }

  @keyframes #{dotElastic}-#{$suffix} {
    0% {
      transform: scale(1, 1);
    }
    25% {
      transform: scale(1, 1);
    }
    50% {
      transform: scale(1, 1.5);
    }
    75% {
      transform: scale(1, 1);
    }
    100% {
      transform: scale(1, 1);
    }
  }

  @keyframes #{dotElasticAfter}-#{$suffix} {
    0% {
      transform: scale(1, 1);
    }
    25% {
      transform: scale(1, 1);
    }
    50% {
      transform: scale(1, 0.67);
    }
    75% {
      transform: scale(1, 1.5);
    }
    100% {
      transform: scale(1, 1);
    }
  }
}

@mixin dotCarousel($suffix: random($limit: 1000), $three-dots-size: 10px, $three-dots-color: '#ACCC59') {
  .dot-carousel {
    position: relative;
    left: -9999px;
    width: #{$three-dots-size};
    height: #{$three-dots-size};
    border-radius: calc(#{$three-dots-size} / 2);
    background-color: #{$three-dots-color};
    color: #{$three-dots-color};
    box-shadow: calc(9999px - #{$three-dots-size} * 1.5) 0 0 0 #{$three-dots-color}, 9999px 0 0 0 #{$three-dots-color},
      calc(9999px + #{$three-dots-size} * 1.5) 0 0 0 #{$three-dots-color};
    animation: #{dotCarousel}-#{$suffix} 1.5s infinite linear;
  }

  @keyframes #{dotCarousel}-#{$suffix} {
    0% {
      box-shadow: calc(9999px - #{$three-dots-size} * 1.5) 0 0 -1px #{$three-dots-color},
        9999px 0 0 1px #{$three-dots-color}, calc(9999px + #{$three-dots-size} * 1.5) 0 0 -1px #{$three-dots-color};
    }
    50% {
      box-shadow: calc(9999px + #{$three-dots-size} * 1.5) 0 0 -1px #{$three-dots-color},
        calc(9999px - #{$three-dots-size} * 1.5) 0 0 -1px #{$three-dots-color}, 9999px 0 0 1px #{$three-dots-color};
    }
    100% {
      box-shadow: 9999px 0 0 1px #{$three-dots-color},
        calc(9999px + #{$three-dots-size} * 1.5) 0 0 -1px #{$three-dots-color},
        calc(9999px - #{$three-dots-size} * 1.5) 0 0 -1px #{$three-dots-color};
    }
  }
}
