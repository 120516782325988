@use 'src/styles/_var' as vars;

$themes: (
  'dark': (
    login-background: vars.$login_background_dark,
    desktop-background: vars.$desktop_background_dark,
    // web-background: vars.$web_background_dark,
    app-updating: vars.$app_updating_dark,
    app-update-error: vars.$app_update_error_dark,
    logo-monogram: vars.$logo_monogram_dark,
    dotted-loader: vars.$dotted_loader_dark,
  ),
  'light': (
    login-background: vars.$login_background_light,
    desktop-background: vars.$desktop_background_light,
    // web-background: vars.$web_background_light,
    app-updating: vars.$app_updating_light,
    app-update-error: vars.$app_update_error_light,
    logo-monogram: vars.$logo_monogram_light,
    dotted-loader: vars.$dotted_loader_light,
  ),
);

@each $theme-name, $theme-colors in $themes {
  .theme.#{$theme-name} {
    // define a css variable for each theme color
    @each $name, $color in $theme-colors {
      --#{$name}: #{$color};
    }
  }
}
