.default-cree8-btn{
  border: 1 * $px solid var(--stroke-default);
  background: linear-gradient(180deg, var(--secondary) 0%, var(--primary) 100%);
  color: var(--text-primary);

  &:hover:not([disabled]),
  &:focus:not([disabled]) {
    background: linear-gradient(180deg, var(--tertiary) 0%, var(--primary) 100%);
    border-color: var(--stroke-hover-active);
    color:var(--negative);
  }
  &:active:not([disabled]) {
    background: linear-gradient(180deg, var(--secondary) 0%, var(--primary) 100%);
    color:var(--button-filled-bg-hover-pressed);
  }

  &:disabled, &[disabled] {
      color:var(--button-filled-text-disabled);
      border: 1 * $px solid var(--stroke-default);
  }
}
