@use '_fonts' as fonts;

@use '_color_themes';

$font-name: fonts.$font-name;

@mixin typography($weight, $font-size, $line-height) {
  font-family: $font-name;
  font-style: normal;
  font-weight: $weight;
  font-size: $font-size;
  line-height: $line-height;
  color: var(--text-primary);
}

@mixin textClamp($clamp: 1) {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  @supports (-webkit-line-clamp: $clamp) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: $clamp;
    -webkit-box-orient: vertical;

    word-break: break-all;
  }
}

@mixin invisibleSelfScrollbar() {
  &::-webkit-scrollbar {
    /* Remove scrollbar space */
    width: 0;
    height: 0;
    /* Optional: just make scrollbar invisible */
    background: transparent;
  }
}

@mixin invisibleScrollbar() {
  ::-webkit-scrollbar {
    /* Remove scrollbar space */
    width: 0;
    height: 0;
    /* Optional: just make scrollbar invisible */
    background: transparent;
  }
}

@mixin defaultSelfAutoHideScrollbar($bgcolor: #1a1a1f) {
  // scrollbar-width: thin;
  // scrollbar-color: transparent var(--scrollbar-color-0);

  &::-webkit-scrollbar {
    height: 6px;
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--scrollbar-color-0);
    border-radius: 16px;
    border: none;
  }

  &::-webkit-scrollbar-thumb:horizontal {
    border-bottom: 2px solid $bgcolor;
  }

  &::-webkit-scrollbar-thumb:vertical {
    border-right: 2px solid $bgcolor;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-corner,
  &::-webkit-resizer {
    background: transparent;
  }
}
