
$white: #fff !default;
$black: #000 !default;

// Variables pro
$tertiary: var(--tertiary) !default;
$box-shadow-4: 0 10px 15px -3px rgba(0, 0, 0, 0.07), 0 4px 6px -2px rgba(0, 0, 0, 0.05) !default;

// scss-docs-start zindex-stack pro
$zindex-timepicker: 1065 !default;
$zindex-datepicker: 1065 !default;
$zindex-datepicker-container: 1066 !default;
// scss-docs-end zindex-stack pro

// Timepicker

// $timepicker-wrapper-z-index: 1071 !default;
$timepicker-wrapper-background-color: rgba(0, 0, 0, 0.4) !default;

$timepicker-wrapper-inline-border-radius: 0.5rem !default;

$timepicker-elements-min-width: 310px !default;
$timepicker-elements-min-height: 325px !default;
$timepicker-elements-background: var(--secondary) !default;
$timepicker-elements-border-top-right-radius: 0.6rem !default;
$timepicker-elements-border-top-left-radius: 0.6rem !default;
$timepicker-elements-media-border-bottom-left-radius: 0.5rem !default;

$timepicker-head-background-color: $tertiary !default;
$timepicker-head-height: 100px !default;
$timepicker-head-border-top-right-radius: 0.5rem !default;
$timepicker-head-border-top-left-radius: 0.5rem !default;
$timepicker-head-padding-y: 10px !default;
$timepicker-head-padding-right: 24px !default;
$timepicker-head-padding-left: 50px !default;

$timepicker-head-media-padding: 10px !default;
$timepicker-head-media-padding-right: 10px !default;
$timepicker-head-media-min-height: 305px !default;

$timepicker-button-font-size: 0.8rem !default;
$timepicker-button-min-width: 64px !default;
$timepicker-button-font-weight: 500 !default;
$timepicker-button-line-height: 40px !default;
$timepicker-button-border-radius: 10px !default;
$timepicker-button-letter-spacing: 0.1rem !default;
$timepicker-button-color: $tertiary !default;
$timepicker-button-transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
  box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !default;
$timepicker-button-hover-background-color: rgba(0, 0, 0, 0.08) !default;
$timepicker-button-focus-background-color: $timepicker-button-hover-background-color !default;
$timepicker-button-padding-x: 10px !default;
$timepicker-button-height: 44px !default;
$timepicker-button-margin-bottom: 10px !default;

$timepicker-submit-inline-height: 48px !default;
$timepicker-submit-inline-min-width: 48px !default;
$timepicker-submit-inline-margin-left: 30px !default;
$timepicker-submit-inline-color: $white !default;

$timepicker-current-font-size: 3.75rem !default;
$timepicker-current-font-weight: 300 !default;
$timepicker-current-line-height: 1.2 !default;
$timepicker-current-letter-spacing: -0.00833em !default;
$timepicker-current-color: $white !default;
$timepicker-current-opacity: 0.54 !default;

$timepicker-mode-wrapper-font-size: 18px !default;
$timepicker-mode-wrapper-color: rgba(255, 255, 255, 0.54) !default;

$timepicker-clock-wrapper-min-width: 310px !default;
$timepicker-clock-wrapper-max-width: 325px !default;
$timepicker-clock-wrapper-min-height: 305px !default;

$timepicker-clock-width: 260px !default;
$timepicker-clock-height: 260px !default;
$timepicker-clock-background-color: rgba(0, 0, 0, 0.07) !default;

$timepicker-clock-inner-top: 50% !default;
$timepicker-clock-inner-left: 50% !default;
$timepicker-clock-inner-transform: translate(-50%, -50%) !default;
$timepicker-clock-inner-width: 160px !default;
$timepicker-clock-inner-height: 160px !default;

$timepicker-time-tips-inner-width: 32px !default;
$timepicker-time-tips-inner-height: 32px !default;
$timepicker-time-tips-inner-font-size: 1.1rem !default;
$timepicker-time-tips-inner-font-weight: 300 !default;
$timepicker-time-tips-inner-active-color: $white !default;
$timepicker-time-tips-inner-active-background-color: $tertiary !default;
$timepicker-time-tips-inner-active-font-weight: 400 !default;
$timepicker-time-tips-inner-disabled-color: #b3afaf !default;

$timepicker-dot-font-weight: 300 !default;
$timepicker-dot-line-height: 1.2 !default;
$timepicker-dot-letter-spacing: -0.00833em !default;
$timepicker-dot-color: $white !default;
$timepicker-dot-font-size: 3.75rem !default;
$timepicker-dot-opacity: 0.54 !default;
$timepicker-dot-media-font-size: 3rem !default;
$timepicker-dot-media-font-weight: 400 !default;

$timepicker-current-inline-font-size: 2.5rem !default;

$timepicker-item-width: 20px !default;
$timepicker-item-height: 20px !default;
$timepicker-item-padding: 10px !default;
$timepicker-item-font-size: 1.1em !default;

$timepicker-item-middle-dot-top: 50% !default;
$timepicker-item-middle-dot-left: 50% !default;
$timepicker-item-middle-dot-width: 6px !default;
$timepicker-item-middle-dot-height: $timepicker-item-middle-dot-width !default;
$timepicker-item-middle-dot-transform: translate(-50%, -50%) !default;
$timepicker-item-middle-dot-border-radius: 50% !default;
$timepicker-item-middle-dot-background-color: $tertiary !default;

$timepicker-hand-pointer-background-color: $tertiary !default;
$timepicker-hand-pointer-bottom: 50% !default;
$timepicker-hand-pointer-height: 40% !default;
$timepicker-hand-pointer-left: calc(50% - 1px) !default;
$timepicker-hand-pointer-width: 2px !default;

$timepicker-transform-transition: transform 400ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
  height 400ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !default;

$timepicker-time-tips-width: 32px !default;
$timepicker-time-tips-height: 32px !default;
$timepicker-time-tips-font-size: 1.1rem !default;
$timepicker-time-tips-active-color: $white !default;

$timepicker-circle-top: -21px !default;
$timepicker-circle-left: -15px !default;
$timepicker-circle-width: 4px !default;
$timepicker-circle-border-width: 14px !default;
$timepicker-circle-border-color: $tertiary !default;
$timepicker-circle-height: 4px !default;

$timepicker-hour-mode-color: $white !default;
$timepicker-hour-mode-opacity: 0.54 !default;
$timepicker-hour-mode-hover-background-color: rgba(0, 0, 0, 0.15) !default;
$timepicker-hour-mode-active-color: $white !default;
$timepicker-hour-mode-font-size: 2.5rem !default;

$timepicker-footer-border-bottom-left-radius: 0.5rem !default;
$timepicker-footer-border-bottom-right-radius: 0.5rem !default;
$timepicker-footer-height: 72px !default;
$timepicker-footer-padding-x: 12px !default;
$timepicker-footer-background-color: var(--secondary) !default;

$timepicker-container-max-height: calc(100% - 64px) !default;

$timepicker-icon-up-down-transition: 0.2s ease all !default;
$timepicker-icon-up-down-transform: translate(-50%, -50%) !default;
$timepicker-icon-up-down-left: 50% !default;
$timepicker-icon-up-down-width: 30px !default;
$timepicker-icon-up-down-height: 30px !default;
$timepicker-icon-up-top: -35px !default;
$timepicker-icon-down-bottom: -47px !default;

$timepicker-icon-btn-width: 30px !default;
$timepicker-icon-btn-height: 30px !default;
$timepicker-input-color: $tertiary !default;

$timepicker-btn-icon-right: 10px !default;
$timepicker-btn-icon-transform: translate(-50%, -50%) !default;
$timepicker-btn-icon-top: 50% !default;
$timepicker-btn-icon-transition: all 0.3s ease !default;
$timepicker-btn-icon-hover-color: $tertiary !default;
$timepicker-btn-icon-focus-color: $tertiary !default;

// Datepicker

$datepicker-toggle-right: 10px !default;
$datepicker-toggle-top: 50% !default;
$datepicker-toggle-focus-color: $tertiary !default;

$datepicker-backdrop-background-color: rgba(0, 0, 0, 0.4) !default;

$datepicker-dropdown-container-width: 328px !default;
$datepicker-dropdown-container-height: 380px !default;
$datepicker-dropdown-container-background-color: $white !default;
$datepicker-dropdown-container-border-radius: 0.5rem !default;
$datepicker-dropdown-container-box-shadow: $box-shadow-4 !default;

$datepicker-modal-container-transform: translate(-50%, -50%) !default;
$datepicker-modal-container-width: 328px !default;
$datepicker-modal-container-height: 512px !default;
$datepicker-modal-container-background-color: var(--secondary) !default;
$datepicker-modal-container-border-radius: 0.6rem 0.6rem 0.5rem 0.5rem !default;
$datepicker-modal-container-box-shadow: $datepicker-dropdown-container-box-shadow !default;
// $datepicker-modal-container-z-index: 1072 !default;
$datepicker-modal-container-date-media-margin-top: 100px !default;
$datepicker-modal-container-day-cell-media-width: 32x !default;
$datepicker-modal-container-day-cell-media-height: $datepicker-modal-container-day-cell-media-width !default;
$datepicker-modal-container-media-width: 475px !default;
$datepicker-modal-container-media-height: 360px !default;
$datepicker-modal-container-media-day-cell-width: 36px !default;
$datepicker-modal-container-media-day-cell-height: $datepicker-modal-container-media-day-cell-width !default;

$datepicker-header-height: 120px !default;
$datepicker-header-padding-x: 24px !default;
$datepicker-header-background-color: $tertiary !default;
$datepicker-header-border-radius: 0.5rem 0.5rem 0 0 !default;

$datepicker-title-height: 32px !default;
$datepicker-title-text-font-size: 10px !default;
$datepicker-title-text-font-weight: 400 !default;
$datepicker-title-text-letter-spacing: 1.7px !default;
$datepicker-title-text-color: $white !default;

$datepicker-date-height: 72px !default;
$datepicker-date-text-font-size: 34px !default;
$datepicker-date-text-font-weight: 400 !default;
$datepicker-date-text-color: $white !default;

$datepicker-date-controls-padding-top: 10px !default;
$datepicker-date-controls-padding-x: 12px !default;

$datepicker-arrow-border-width: 5px !default;
$datepicker-arrow-margin-left: 5px !default;
$datepicker-arrow-transform: rotate(180deg) !default;

$datepicker-date-controls-color: rgba(0, 0, 0, 0.64) !default;

$datepicker-view-change-button-padding: 10px !default;
$datepicker-view-change-button-color: $white !default;
$datepicker-view-change-button-font-weight: 500 !default;
$datepicker-view-change-button-font-size: 0.9rem !default;
$datepicker-view-change-button-border-radius: 10px !default;
$datepicker-view-change-button-state-background-color: var(--accent-blue-100) !default;
$datepicker-view-change-button-after-border-width: 5px !default;
$datepicker-view-change-button-after-margin-left: 5px !default;

$datepicker-arrow-controls-margin-top: 10px !default;

$datepicker-previous-button-width: 40px !default;
$datepicker-previous-button-height: $datepicker-previous-button-width !default;
$datepicker-previous-button-line-height: $datepicker-previous-button-height !default;
$datepicker-previous-button-color: $white !default;
$datepicker-previous-button-margin-right: 24px !default;
$datepicker-previous-button-state-background-color: var(--primary) !default;
$datepicker-previous-button-state-border-radius: 50% !default;

$datepicker-previous-button-after-margin: 15.5px !default;
$datepicker-previous-button-after-border-width: 2px !default;
$datepicker-previous-button-after-transform: translateX(2px) rotate(-45deg) !default;

$datepicker-next-button-width: 40px !default;
$datepicker-next-button-height: $datepicker-next-button-width !default;
$datepicker-next-button-line-height: $datepicker-next-button-height !default;
$datepicker-next-button-color: $white !default;
$datepicker-next-button-margin-background-color: var(--primary) !default;
$datepicker-next-button-state-border-radius: 50% !default;

$datepicker-next-button-after-margin: 15.5px !default;
$datepicker-next-button-after-border-width: 2px !default;
$datepicker-next-button-after-transform: translateX(-2px) rotate(45deg) !default;

$datepicker-view-padding-x: 12px !default;
$datepicker-table-width: 304px !default;

$datepicker-day-heading-width: 40px !default;
$datepicker-day-heading-height: $datepicker-day-heading-width !default;
$datepicker-day-heading-font-size: 12px !default;
$datepicker-day-heading-font-weight: 400 !default;

$datepicker-cell-disabled-color: var(--primary-grey-20) !default;
$datepicker-cell-hover-background-color: var(--primary) !default;
$datepicker-cell-selected-background-color: $tertiary !default;
$datepicker-cell-selected-color: $white !default;
$datepicker-cell-focused-background-color: var(--tertiary) !default;
$datepicker-cell-focused-selected-background-color: $tertiary !default;
$datepicker-cell-border-width: 1px !default;
$datepicker-cell-border-color: var(--tertiary) !default;

$datepicker-small-cell-width: 40px !default;
$datepicker-small-cell-height: $datepicker-small-cell-width !default;

$datepicker-small-cell-content-width: 36px !default;
$datepicker-small-cell-content-height: $datepicker-small-cell-content-width !default;
$datepicker-small-cell-content-line-height: $datepicker-small-cell-content-height !default;
$datepicker-small-cell-content-border-radius: 50% !default;
$datepicker-small-cell-content-font-size: 13px !default;

$datepicker-large-cell-width: 76px !default;
$datepicker-large-cell-height: 42px !default;

$datepicker-large-cell-content-width: 72px !default;
$datepicker-large-cell-content-height: 40px !default;
$datepicker-large-cell-content-line-height: $datepicker-large-cell-content-height !default;
$datepicker-large-cell-content-padding-y: 1px !default;
$datepicker-large-cell-content-padding-x: 2px !default;
$datepicker-large-cell-content-border-radius: 999px !default;

$datepicker-yearview-content-padding-x: $datepicker-view-padding-x !default;

$datepicker-footer-height: 72px !default;
$datepicker-footer-padding-x: $datepicker-yearview-content-padding-x !default;

// background-color: var(--button-filled-bg-default);
// color: var(--button-filled-text-default);
$datepicker-footer-btn-background-color: var(--button-filled-bg-default) !default;
$datepicker-footer-btn-color: var(--button-filled-text-default) !default;
$datepicker-footer-btn-padding-x: 10px !default;
$datepicker-footer-btn-font-size: 0.8rem !default;
$datepicker-footer-btn-font-weight: 500 !default;
$datepicker-footer-btn-height: 44px !default;
$datepicker-footer-btn-line-height: $datepicker-footer-btn-height !default;
$datepicker-footer-btn-letter-spacing: 0.1rem !default;
$datepicker-footer-btn-border-radius: 8px !default;
$datepicker-footer-btn-margin-bottom: 0px !default;
$datepicker-footer-btn-state-background-color: var(--button-filled-bg-hover-pressed) !default;

.datepicker-toggle-button {
  position: absolute;
  outline: none;
  border: none;
  background-color: transparent;
  right: $datepicker-toggle-right;
  top: $datepicker-toggle-top;
  transform: translate(-50%, -50%);

  &:focus {
    color: $datepicker-toggle-focus-color;
  }

  &:hover {
    color: $datepicker-toggle-focus-color;
  }
}

.datepicker-backdrop {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: $datepicker-backdrop-background-color;
  z-index: $zindex-datepicker;
}

.datepicker-dropdown-container {
  width: $datepicker-dropdown-container-width;
  height: $datepicker-dropdown-container-height;
  background-color: $datepicker-dropdown-container-background-color;
  border-radius: $datepicker-dropdown-container-border-radius;
  box-shadow: $datepicker-dropdown-container-box-shadow;
  z-index: $zindex-datepicker-container;
}

.datepicker-modal-container {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: $datepicker-modal-container-transform;
  width: $datepicker-modal-container-width;
  height: $datepicker-modal-container-height;
  background-color: $datepicker-modal-container-background-color;
  border-radius: $datepicker-modal-container-border-radius;
  box-shadow: $datepicker-modal-container-box-shadow;
  z-index: $zindex-datepicker-container;

  & .datepicker-header {
    @media screen and (min-width: 320px) and (max-width: 820px) and (orientation: landscape) {
      height: 100%;
    }
  }

  & .datepicker-date {
    @media screen and (min-width: 320px) and (max-width: 820px) and (orientation: landscape) {
      margin-top: $datepicker-modal-container-date-media-margin-top;
    }
  }

  & .datepicker-day-cell {
    @media screen and (min-width: 320px) and (max-width: 820px) and (orientation: landscape) {
      width: $datepicker-modal-container-day-cell-media-width;
      height: $datepicker-modal-container-day-cell-media-height;
    }
  }

  @media screen and (min-width: 320px) and (max-width: 820px) and (orientation: landscape) {
    flex-direction: row;
    width: $datepicker-modal-container-media-width;
    height: $datepicker-modal-container-media-height;

    &.datepicker-day-cell {
      width: $datepicker-modal-container-media-day-cell-width;
      height: $datepicker-modal-container-media-day-cell-height;
    }
  }
}

.datepicker-header {
  height: $datepicker-header-height;
  padding-right: $datepicker-header-padding-x;
  padding-left: $datepicker-header-padding-x;
  background-color: $datepicker-header-background-color;
  display: flex;
  flex-direction: column;
  border-radius: $datepicker-header-border-radius;
}

.datepicker-title {
  height: $datepicker-title-height;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.datepicker-title-text {
  font-size: $datepicker-title-text-font-size;
  font-weight: $datepicker-title-text-font-weight;
  text-transform: uppercase;
  letter-spacing: $datepicker-title-text-letter-spacing;
  color: $datepicker-title-text-color;
}

.datepicker-date {
  height: $datepicker-date-height;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.datepicker-date-text {
  font-size: $datepicker-date-text-font-size;
  font-weight: $datepicker-date-text-font-weight;
  color: $datepicker-date-text-color;
}

.datepicker-main {
  position: relative;
  height: 100%;
}

.datepicker-date-controls {
  padding: $datepicker-date-controls-padding-top $datepicker-date-controls-padding-x 0
    $datepicker-date-controls-padding-x;
  display: flex;
  justify-content: space-between;
}

.datepicker-arrow {
  display: inline-block;
  width: 0;
  height: 0;
  border-left: $datepicker-arrow-border-width solid transparent;
  border-right: $datepicker-arrow-border-width solid transparent;
  border-top-width: $datepicker-arrow-border-width;
  border-top-style: solid;
  margin: 0 0 0 $datepicker-arrow-margin-left;
  vertical-align: middle;
}

.datepicker-arrow-up {
  display: inline-block;
  width: 0;
  height: 0;
  border-left: $datepicker-arrow-border-width solid transparent;
  border-right: $datepicker-arrow-border-width solid transparent;
  border-top-width: $datepicker-arrow-border-width;
  border-top-style: solid;
  margin: 0 0 0 $datepicker-arrow-margin-left;
  vertical-align: middle;
  transform: $datepicker-arrow-transform;
}

.datepicker-date-controls {
  color: $datepicker-date-controls-color;
}

.datepicker-view-change-button {
  padding: $datepicker-view-change-button-padding;
  color: $datepicker-view-change-button-color;
  font-weight: $datepicker-view-change-button-font-weight;
  font-size: $datepicker-view-change-button-font-size;
  border-radius: $datepicker-view-change-button-border-radius;
  box-shadow: none;
  background-color: transparent;
  margin: 0;
  border: none;

  &:hover,
  &:focus {
    background-color: $datepicker-view-change-button-state-background-color;
  }

  &:after {
    content: '';
    display: inline-block;
    width: 0;
    height: 0;
    border-left: $datepicker-view-change-button-after-border-width solid transparent;
    border-right: $datepicker-view-change-button-after-border-width solid transparent;
    border-top-width: $datepicker-view-change-button-after-border-width;
    border-top-style: solid;
    margin: 0 0 0 $datepicker-view-change-button-after-margin-left;
    vertical-align: middle;
  }
}

.datepicker-arrow-controls {
  margin-top: $datepicker-arrow-controls-margin-top;
}

/*!rtl:begin:options:
{
"autoRename": true,
"stringMap":[
    {
    "name"    : "prev-next",
    "search"  : ["datepicker-previous-button", "datepicker-next-button"],
    "replace" : ["datepicker-next-button", "datepicker-previous-button"],
    "options" : {"ignoreCase":false}
    }
]
}
*/
.datepicker-previous-button {
  position: relative;
  padding: 0;
  width: $datepicker-previous-button-width;
  height: $datepicker-previous-button-height;
  line-height: $datepicker-previous-button-line-height;
  border: none;
  outline: none;
  margin: 0;
  color: $datepicker-previous-button-color;
  background-color: transparent;
  margin-right: $datepicker-previous-button-margin-right;

  &:hover,
  &:focus {
    background-color: $datepicker-previous-button-state-background-color;
    border-radius: $datepicker-previous-button-state-border-radius;
  }
}

.datepicker-previous-button::after {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  content: '';
  margin: $datepicker-previous-button-after-margin;
  border: 0 solid currentColor;
  border-top-width: $datepicker-previous-button-after-border-width;
  border-left-width: $datepicker-previous-button-after-border-width;
  transform: $datepicker-previous-button-after-transform;
}

.datepicker-next-button {
  position: relative;
  padding: 0;
  width: $datepicker-next-button-width;
  height: $datepicker-next-button-height;
  line-height: $datepicker-next-button-line-height;
  border: none;
  outline: none;
  margin: 0;
  color: $datepicker-next-button-color;
  background-color: transparent;

  &:hover,
  &:focus {
    background-color: $datepicker-next-button-margin-background-color;
    border-radius: $datepicker-next-button-state-border-radius;
  }
}

.datepicker-next-button::after {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  content: '';
  margin: $datepicker-next-button-after-margin;
  border: 0 solid currentColor;
  border-top-width: $datepicker-next-button-after-border-width;
  border-right-width: $datepicker-next-button-after-border-width;
  transform: $datepicker-next-button-after-transform;
}
/*!rtl:end:options*/

.datepicker-view {
  padding-left: $datepicker-view-padding-x;
  padding-right: $datepicker-view-padding-x;
  outline: none;
}

.datepicker-table {
  margin-right: auto;
  margin-left: auto;
  width: $datepicker-table-width;
}

.datepicker-day-heading {
  width: $datepicker-day-heading-width;
  height: $datepicker-day-heading-height;
  text-align: center;
  font-size: $datepicker-day-heading-font-size;
  font-weight: $datepicker-day-heading-font-weight;
}

.datepicker-cell {
  text-align: center;

  &.disabled {
    color: $datepicker-cell-disabled-color;
    cursor: default;
    pointer-events: none;
  }

  &.disabled:hover {
    cursor: default;
  }

  &:hover {
    cursor: pointer;
  }
}

.datepicker-cell:not(.disabled):not(.selected):hover .datepicker-cell-content {
  background-color: $datepicker-cell-hover-background-color;
}

.datepicker-cell.selected .datepicker-cell-content {
  background-color: $datepicker-cell-selected-background-color;
  color: $datepicker-cell-selected-color;
}

.datepicker-cell:not(.selected).focused .datepicker-cell-content {
  background-color: $datepicker-cell-focused-background-color;
}

.datepicker-cell.focused .datepicker-cell-content.selected {
  background-color: $datepicker-cell-focused-selected-background-color;
}

.datepicker-cell.current .datepicker-cell-content {
  border: $datepicker-cell-border-width solid $datepicker-cell-border-color;
}

.datepicker-small-cell {
  width: $datepicker-small-cell-width;
  height: $datepicker-small-cell-height;
}

.datepicker-small-cell-content {
  width: $datepicker-small-cell-content-width;
  height: $datepicker-small-cell-content-height;
  line-height: $datepicker-small-cell-content-line-height;
  border-radius: $datepicker-small-cell-content-border-radius;
  font-size: $datepicker-small-cell-content-font-size;
}

.datepicker-large-cell {
  width: $datepicker-large-cell-width;
  height: $datepicker-large-cell-height;
}

.datepicker-large-cell-content {
  width: $datepicker-large-cell-content-width;
  height: $datepicker-large-cell-content-height;
  line-height: $datepicker-large-cell-content-line-height;
  padding: $datepicker-large-cell-content-padding-y $datepicker-large-cell-content-padding-x;
  border-radius: $datepicker-large-cell-content-border-radius;
  display: flex;
  justify-content: center;
  align-items: center;
}

.datepicker-yearview-content {
  padding-left: $datepicker-yearview-content-padding-x;
  padding-right: $datepicker-yearview-content-padding-x;
}

.datepicker-footer {
  height: $datepicker-footer-height;
  display: flex;
  position: absolute;
  width: 100%;
  bottom: 0;
  justify-content: center;
  align-items: center;
  padding-left: $datepicker-footer-padding-x;
  padding-right: $datepicker-footer-padding-x;
  gap: 8px;
}

.datepicker-footer-btn {
  background-color: $datepicker-footer-btn-background-color;
  color: $datepicker-footer-btn-color;
  border: none;
  cursor: pointer;
  padding: 0 $datepicker-footer-btn-padding-x;
  text-transform: uppercase;
  font-size: $datepicker-footer-btn-font-size;
  font-weight: $datepicker-footer-btn-font-weight;
  height: $datepicker-footer-btn-height;
  // line-height: $datepicker-footer-btn-line-height;
  letter-spacing: $datepicker-footer-btn-letter-spacing;
  border-radius: $datepicker-footer-btn-border-radius;
  margin-bottom: $datepicker-footer-btn-margin-bottom;
  font-weight: 700;
  flex: 1;
  padding: 8px 16px;

  &:hover,
  &:focus {
    background-color: $datepicker-footer-btn-state-background-color;
  }

  &.datepicker-cancel-btn {
    color: var(--button-outlined-text-default);
    background: transparent;
    border: 1px solid transparent;

    &:hover,
    &:focus {
      background-color: transparent;
      border: 1px solid transparent;
      color: var(--button-outlined-text-hover-pressed);
    }
  }
}

.datepicker-clear-btn {
  margin-right: auto;
  display: none;
}

.datepicker,
.datepicker-backdrop,
.datepicker-modal-container {
  &.animation {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    padding: auto;
  }
  &.fade-in {
    -webkit-animation-name: _fade-in;
    animation-name: _fade-in;
  }
  &.fade-out {
    -webkit-animation-name: _fade-out;
    animation-name: _fade-out;
  }
  &.animation.infinite {
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
  }
  &.animation.delay-1s {
    -webkit-animation-delay: 1s;
    animation-delay: 1s;
  }
  &.animation.delay-2s {
    -webkit-animation-delay: 2s;
    animation-delay: 2s;
  }
  &.animation.delay-3s {
    -webkit-animation-delay: 3s;
    animation-delay: 3s;
  }
  &.animation.delay-4s {
    -webkit-animation-delay: 4s;
    animation-delay: 4s;
  }
  &.animation.delay-5s {
    -webkit-animation-delay: 5s;
    animation-delay: 5s;
  }
  &.animation.fast {
    -webkit-animation-duration: 800ms;
    animation-duration: 800ms;
  }
  &.animation.faster {
    -webkit-animation-duration: 500ms;
    animation-duration: 500ms;
  }
  &.animation.slow {
    -webkit-animation-duration: 2s;
    animation-duration: 2s;
  }
  &.animation.slower {
    -webkit-animation-duration: 3s;
    animation-duration: 3s;
  }
  &.fade-in-down {
    -webkit-animation-name: _fade-in-down;
    animation-name: _fade-in-down;
  }
  &.fade-in-left {
    -webkit-animation-name: _fade-in-left;
    animation-name: _fade-in-left;
  }
  &.fade-in-right {
    -webkit-animation-name: _fade-in-right;
    animation-name: _fade-in-right;
  }
  &.fade-in-up {
    -webkit-animation-name: _fade-in-up;
    animation-name: _fade-in-up;
  }
  &.fade-out-down {
    -webkit-animation-name: _fade-out-down;
    animation-name: _fade-out-down;
  }
  &.fade-out-left {
    -webkit-animation-name: _fade-out-left;
    animation-name: _fade-out-left;
  }
  &.fade-out-right {
    -webkit-animation-name: _fade-out-right;
    animation-name: _fade-out-right;
  }
  &.fade-out-up {
    -webkit-animation-name: _fade-out-up;
    animation-name: _fade-out-up;
  }
  &.slide-in-down {
    -webkit-animation-name: _slide-in-down;
    animation-name: _slide-in-down;
  }
  &.slide-in-left {
    -webkit-animation-name: _slide-in-left;
    animation-name: _slide-in-left;
  }
  &.slide-in-right {
    -webkit-animation-name: _slide-in-right;
    animation-name: _slide-in-right;
  }
  &.slide-in-up {
    -webkit-animation-name: _slide-in-up;
    animation-name: _slide-in-up;
  }
  &.slide-out-down {
    -webkit-animation-name: _slide-out-down;
    animation-name: _slide-out-down;
  }
  &.slide-out-left {
    -webkit-animation-name: _slide-out-left;
    animation-name: _slide-out-left;
  }
  &.slide-out-right {
    -webkit-animation-name: _slide-out-right;
    animation-name: _slide-out-right;
  }
  &.slide-out-up {
    -webkit-animation-name: _slide-out-up;
    animation-name: _slide-out-up;
  }
  &.slide-down {
    -webkit-animation-name: _slide-down;
    animation-name: _slide-down;
  }
  &.slide-left {
    -webkit-animation-name: _slide-left;
    animation-name: _slide-left;
  }
  &.slide-right {
    -webkit-animation-name: _slide-right;
    animation-name: _slide-right;
  }
  &.slide-up {
    -webkit-animation-name: _slide-up;
    animation-name: _slide-up;
  }
  &.zoom-in {
    -webkit-animation-name: _zoom-in;
    animation-name: _zoom-in;
  }
  &.zoom-out {
    -webkit-animation-name: _zoom-out;
    animation-name: _zoom-out;
  }
  &.tada {
    -webkit-animation-name: _tada;
    animation-name: _tada;
  }
  &.pulse {
    -webkit-animation-name: _pulse;
    animation-name: _pulse;
  }
  &.ripple-surface {
    position: relative;
    overflow: hidden;
    display: inline-block;
    vertical-align: bottom;
  }
  &.ripple-surface-unbound {
    overflow: visible;
  }
  &.ripple-wave {
    background-image: radial-gradient(
      circle,
      rgba(0, 0, 0, 0.2) 0,
      rgba(0, 0, 0, 0.3) 40%,
      rgba(0, 0, 0, 0.4) 50%,
      rgba(0, 0, 0, 0.5) 60%,
      transparent 70%
    );
    border-radius: 50%;
    opacity: 0.5;
    pointer-events: none;
    position: absolute;
    touch-action: none;
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
    -webkit-transition-property: transform, opacity;
    transition-property: transform, opacity;
    -webkit-transition-timing-function: cubic-bezier(0, 0, 0.15, 1), cubic-bezier(0, 0, 0.15, 1);
    transition-timing-function: cubic-bezier(0, 0, 0.15, 1), cubic-bezier(0, 0, 0.15, 1);
    z-index: 999;
  }
  &.ripple-wave.active {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }
}

// time picker

.timepicker {
  &-wrapper {
    touch-action: none;
    z-index: $zindex-timepicker;
    opacity: 1;
    right: 0;
    bottom: 0;
    top: 0;
    left: 0;
    background-color: $timepicker-wrapper-background-color;
  }

  &-wrapper-inline {
    border-radius: $timepicker-wrapper-inline-border-radius;
  }

  &-elements {
    min-width: $timepicker-elements-min-width;
    min-height: $timepicker-elements-min-height;
    background: $timepicker-elements-background;
    border-top-right-radius: $timepicker-elements-border-top-right-radius;
    border-top-left-radius: $timepicker-elements-border-top-left-radius;

    @media screen and (min-width: 320px) and (max-width: 825px) and (orientation: landscape) {
      flex-direction: row !important;
      border-bottom-left-radius: $timepicker-elements-media-border-bottom-left-radius;
      min-width: auto;
      min-height: auto;
      overflow-y: auto;
    }
  }

  &-elements-inline {
    min-height: auto;
  }

  &-head {
    background-color: $timepicker-head-background-color;
    height: $timepicker-head-height;
    border-top-right-radius: $timepicker-head-border-top-right-radius;
    border-top-left-radius: $timepicker-head-border-top-left-radius;
    padding: $timepicker-head-padding-y $timepicker-head-padding-right $timepicker-head-padding-y
      $timepicker-head-padding-left;

    @media screen and (min-width: 320px) and (max-width: 825px) and (orientation: landscape) {
      border-top-right-radius: 0;
      border-bottom-left-radius: 0;
      padding: $timepicker-head-media-padding;
      padding-right: $timepicker-head-media-padding-right !important;
      height: auto;
      min-height: $timepicker-head-media-min-height;
    }
  }

  &-head-inline {
    padding: 0;
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }

  &-head-content {
    @media screen and (min-width: 320px) and (max-width: 825px) and (orientation: landscape) {
      flex-direction: column;
    }
  }

  &-clear {
    display: none;
  }

  &-button {
    font-size: $timepicker-button-font-size;
    min-width: $timepicker-button-min-width;
    box-sizing: border-box;
    font-weight: $timepicker-button-font-weight;
    // line-height: $timepicker-button-line-height;
    border-radius: $timepicker-button-border-radius;
    letter-spacing: $timepicker-button-letter-spacing;
    text-transform: uppercase;
    color: var(--text-primary);
    border: none;
    background-color: transparent;
    transition: $timepicker-button-transition;
    outline: none;
    padding: 8px 16px;
    height: $timepicker-button-height;
    // margin-bottom: $timepicker-button-margin-bottom;

    flex: 1;

    &:hover {
      background-color: $timepicker-button-hover-background-color;
    }

    &:focus {
      outline: none;
      background-color: $timepicker-button-focus-background-color;
    }
  }

  &-submit {
    padding: 0;
    background-color: var(--button-filled-bg-default);
    color: var(--button-filled-text-default);
    border-radius: 8px;
    font-weight: 700;

    &:hover {
      background-color: var(--button-filled-bg-hover-pressed);
    }

    &:focus {
      background-color: var(--button-filled-bg-hover-pressed);
    }
  }

  &-cancel {
    padding: 0;

    margin-left: 4px;

    display: flex;
    align-items: center;
    justify-content: center;

    background: transparent;
    border: 1px solid transparent;
    border-radius: 8px;
    color: var(--button-outlined-text-default);
    font-weight: 700;

    &:hover,
    &:focus {
      background: transparent;
      border: 1px solid transparent;
      color: var(--button-outlined-text-hover-pressed);
      font-weight: 700;
    }
  }

  &-submit-inline {
    border-radius: 100%;
    height: $timepicker-submit-inline-height;
    min-width: $timepicker-submit-inline-min-width;
    display: inline-block;
    margin-left: $timepicker-submit-inline-margin-left;
    color: $timepicker-submit-inline-color;
  }

  &-current {
    font-size: $timepicker-current-font-size;
    font-weight: $timepicker-current-font-weight;
    line-height: $timepicker-current-line-height;
    letter-spacing: $timepicker-current-letter-spacing;
    color: $timepicker-current-color;
    opacity: $timepicker-current-opacity;
    border: none;
    background: transparent;
    padding: 0;

    &.active {
      opacity: 1;
    }

    &-wrapper {
      direction: ltr #{'/*!rtl:ignore*/'};
    }
  }

  &-mode-wrapper {
    margin-left: 20px;
    font-size: $timepicker-mode-wrapper-font-size;
    color: $timepicker-mode-wrapper-color;

    @media screen and (min-width: 320px) and (max-width: 825px) and (orientation: landscape) {
      justify-content: space-around !important;
      flex-direction: row !important;
    }

    &.active {
      opacity: 1;
    }
  }

  &-clock-wrapper {
    min-width: $timepicker-clock-wrapper-min-width;
    max-width: $timepicker-clock-wrapper-max-width;
    min-height: $timepicker-clock-wrapper-min-height;
    overflow-x: hidden;
    height: 100%;
  }

  &-clock {
    position: relative;
    border-radius: 100%;
    width: $timepicker-clock-width;
    height: $timepicker-clock-height;
    cursor: default;
    margin: 0 auto;
    background-color: $timepicker-clock-background-color;
  }

  &-clock-inner {
    position: absolute;
    top: $timepicker-clock-inner-top;
    left: $timepicker-clock-inner-left;
    transform: $timepicker-clock-inner-transform;
    width: $timepicker-clock-inner-width;
    height: $timepicker-clock-inner-height;
    border-radius: 100%;
  }

  &-time-tips-minutes,
  &-time-tips-inner,
  &-time-tips-hours {
    position: absolute;
    border-radius: 100%;
    width: $timepicker-time-tips-inner-width;
    height: $timepicker-time-tips-inner-height;
    text-align: center;
    cursor: pointer;
    font-size: $timepicker-time-tips-inner-font-size;
    border-radius: 100%;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: $timepicker-time-tips-inner-font-weight;

    &.active {
      color: $timepicker-time-tips-inner-active-color;
      background-color: $timepicker-time-tips-inner-active-background-color;
      font-weight: $timepicker-time-tips-inner-active-font-weight;
    }

    &:focus {
      outline: none;
    }

    &.disabled {
      color: $timepicker-time-tips-inner-disabled-color;
      pointer-events: none;
      background-color: transparent;
    }

    &::selection {
      background-color: transparent;
    }
  }

  &-current,
  &-dot {
    font-weight: $timepicker-dot-font-weight;
    line-height: $timepicker-dot-line-height;
    letter-spacing: $timepicker-dot-letter-spacing;
    color: $timepicker-dot-color;

    @media screen and (min-width: 320px) and (max-width: 825px) and (orientation: landscape) {
      font-size: $timepicker-dot-media-font-size;
      font-weight: $timepicker-dot-media-font-weight;
    }
  }

  &-dot {
    font-size: $timepicker-dot-font-size;
    opacity: $timepicker-dot-opacity;
    border: none;
    background: transparent;
    padding: 0;
  }

  &-current-inline {
    font-size: $timepicker-current-inline-font-size;
  }

  &-item {
    position: absolute;
    border-radius: 100%;
    width: $timepicker-item-width;
    height: $timepicker-item-height;
    text-align: center;
    padding: $timepicker-item-padding;
    user-select: none;
    cursor: default;
    font-size: $timepicker-item-font-size;
    z-index: 3;
  }

  &-middle-dot {
    top: $timepicker-item-middle-dot-top;
    left: $timepicker-item-middle-dot-left;
    width: $timepicker-item-middle-dot-width;
    height: $timepicker-item-middle-dot-height;
    transform: $timepicker-item-middle-dot-transform;
    border-radius: $timepicker-item-middle-dot-border-radius;
    background-color: $timepicker-item-middle-dot-background-color;
  }

  &-hand-pointer {
    background-color: $timepicker-hand-pointer-background-color;
    bottom: $timepicker-hand-pointer-bottom;
    height: $timepicker-hand-pointer-height;
    left: $timepicker-hand-pointer-left #{'/*!rtl:ignore*/'};
    transform-origin: center bottom 0 #{'/*!rtl:ignore*/'};
    width: $timepicker-hand-pointer-width;
  }

  &-transform {
    transition: $timepicker-transform-transition;
  }

  &-time-tips {
    position: absolute;
    border-radius: 100%;
    width: $timepicker-time-tips-width;
    height: $timepicker-time-tips-height;
    text-align: center;
    cursor: pointer;
    font-size: $timepicker-time-tips-font-size;

    &.active {
      color: $timepicker-time-tips-active-color;
    }
  }

  &-circle {
    top: $timepicker-circle-top;
    left: $timepicker-circle-left;
    width: $timepicker-circle-width;
    border: $timepicker-circle-border-width solid $timepicker-circle-border-color;
    height: $timepicker-circle-height;
    box-sizing: content-box;
    border-radius: 100%;
  }

  &-hour-mode {
    padding: 0;
    background-color: transparent;
    border: none;
    color: $timepicker-hour-mode-color;
    opacity: $timepicker-hour-mode-opacity;
  }

  &-hour-mode,
  &-hour,
  &-minute {
    cursor: pointer;

    &:hover,
    &:focus {
      background-color: $timepicker-hour-mode-hover-background-color;
      outline: none;
    }

    &.active {
      color: $timepicker-hour-mode-active-color;
      opacity: 1;
    }

    &-current-inline {
      font-size: $timepicker-hour-mode-font-size;
    }
  }

  &-footer {
    border-bottom-left-radius: $timepicker-footer-border-bottom-left-radius;
    border-bottom-right-radius: $timepicker-footer-border-bottom-right-radius;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: $timepicker-footer-height;
    padding-left: $timepicker-footer-padding-x;
    padding-right: $timepicker-footer-padding-x;
    background-color: $timepicker-footer-background-color;
  }

  &-container {
    max-height: $timepicker-container-max-height;
    overflow-y: auto;
    box-shadow: $box-shadow-4;
  }

  &-icon-up,
  &-icon-down {
    opacity: 0;
    transition: $timepicker-icon-up-down-transition;
    cursor: pointer;
    transform: $timepicker-icon-up-down-transform;
    left: $timepicker-icon-up-down-left;

    width: $timepicker-icon-up-down-width;
    height: $timepicker-icon-up-down-height;
    display: flex;
    justify-content: center;
    align-items: center;

    &.active,
    &:hover {
      opacity: 1;
    }
  }

  &-icon-up {
    top: $timepicker-icon-up-top;
  }

  &-icon-down {
    bottom: $timepicker-icon-down-bottom;
  }

  &-inline-hour-icons,
  &-inline-minutes-icons {
    button {
      opacity: 1 !important;
      cursor: default;

      &:hover {
        background-color: unset !important;
      }
    }
  }

  &-clock-animation {
    animation: show-up-clock 350ms linear;
  }

  &-icon-btn {
    width: $timepicker-icon-btn-width;
    height: $timepicker-icon-btn-height;
  }

  &-toggle-button {
    position: absolute;
    outline: none;
    border: none;
    background-color: transparent;
    right: $timepicker-btn-icon-right;
    top: $timepicker-btn-icon-top;
    transform: $timepicker-btn-icon-transform;
    transition: $timepicker-btn-icon-transition;
    cursor: pointer;

    &:hover {
      color: $timepicker-btn-icon-hover-color;
    }

    &:focus {
      color: $timepicker-btn-icon-focus-color;
    }
  }

  &-input:focus + &-toggle-button,
  &-input:focus + &-toggle-button i {
    color: $timepicker-input-color;
  }

  a.timepicker-toggle-button,
  &-toggle-button.timepicker-icon {
    right: 1px;
  }
}

@keyframes show-up-clock {
  0% {
    opacity: 0;
    transform: scale(0.7);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}

.justify-content-evenly {
  justify-content: space-evenly;
}

.timepicker-modal {
  z-index: $zindex-timepicker;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  .fade {
    &.show {
      opacity: 1;
    }
  }
}
