/* custom checkbox */
@use 'sass:math';
@use 'src/styles/_var' as vars;

$px: vars.$px;

$checked-color: var(--text_success);
$unchecked-color: var(--border_primary);
$checkbox-height: 20 * $px;
$background-color: var(--bg);
$font-color: #efefef;
$duration: 0.4s;

@keyframes dothabottomcheck {
  0% {
    height: 0;
  }
  100% {
    height: math.div($checkbox-height, 2);
  }
}

@keyframes dothatopcheck {
  0% {
    height: 0;
  }
  50% {
    height: 0;
  }
  100% {
    height: $checkbox-height * 1.2;
  }
}

.custom-checkbox {
  min-width: 250 * $px;
  display: inline;

  input[type='checkbox'] {
    display: none;
  }

  .check-box {
    height: $checkbox-height;
    width: $checkbox-height;
    background-color: transparent;
    border: $checkbox-height * 0.1 solid $unchecked-color;
    border-radius: 5 * $px;
    position: relative;
    display: inline-block;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -moz-transition: border-color ease math.div($duration, 2);
    -o-transition: border-color ease math.div($duration, 2);
    -webkit-transition: border-color ease math.div($duration, 2);
    transition: border-color ease math.div($duration, 2);
    cursor: pointer;

    &::before,
    &::after {
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      position: absolute;
      height: 0;
      width: $checkbox-height * 0.2;
      background-color: $checked-color;
      display: inline-block;
      -moz-transform-origin: left top;
      -ms-transform-origin: left top;
      -o-transform-origin: left top;
      -webkit-transform-origin: left top;
      transform-origin: left top;
      border-radius: 5 * $px;
      content: ' ';
      -webkit-transition: opacity ease 0.5;
      -moz-transition: opacity ease 0.5;
      transition: opacity ease 0.5;
    }

    &::before {
      top: $checkbox-height * 0.72;
      left: $checkbox-height * 0.41;
      box-shadow: 0 0 0 $checkbox-height * 0.05 $background-color;
      -moz-transform: rotate(-135deg);
      -ms-transform: rotate(-135deg);
      -o-transform: rotate(-135deg);
      -webkit-transform: rotate(-135deg);
      transform: rotate(-135deg);
    }

    &::after {
      top: $checkbox-height * 0.37;
      left: $checkbox-height * 0.05;
      -moz-transform: rotate(-45deg);
      -ms-transform: rotate(-45deg);
      -o-transform: rotate(-45deg);
      -webkit-transform: rotate(-45deg);
      transform: rotate(-45deg);
    }
  }

  input[type='checkbox']:checked + .check-box,
  .check-box.checked {
    border-color: $checked-color;

    &::after {
      height: $checkbox-height * 0.5;
      -moz-animation: dothabottomcheck math.div($duration, 2) ease 0s forwards;
      -o-animation: dothabottomcheck math.div($duration, 2) ease 0s forwards;
      -webkit-animation: dothabottomcheck math.div($duration, 2) ease 0s forwards;
      animation: dothabottomcheck math.div($duration, 2) ease 0s forwards;
    }

    &::before {
      height: $checkbox-height * 1.2;
      -moz-animation: dothatopcheck $duration ease 0s forwards;
      -o-animation: dothatopcheck $duration ease 0s forwards;
      -webkit-animation: dothatopcheck $duration ease 0s forwards;
      animation: dothatopcheck $duration ease 0s forwards;
    }
  }

  span {
    margin-left: 12 * $px;
    vertical-align: super;
  }
}

.custom-radio {
  width: 25 * $px;
  display: inline-block;

  input {
    display: none;
  }

  input:checked + .check-icon:before {
    content: '✔';
  }
}
