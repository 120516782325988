@use 'src/styles/_var' as vars;
@use 'src/styles/_color_themes';
@use 'src/styles/_urls';
@use 'src/styles/_input_styles';
@use 'src/styles/base';

@tailwind base;
@tailwind components;
@tailwind utilities;

$px: vars.$px;

* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
}

img,
video {
  max-width: 100%;
  height: auto;
}

img,
svg,
video,
canvas,
audio,
iframe,
embed,
object {
  display: block;
  vertical-align: middle;
}

.clickable {
  cursor: pointer;
  user-select: none;
}

.flex {
  display: flex;
}
.m-t-xs {
  margin-top: 5 * $px;
}
.m-t-sm {
  margin-top: 10 * $px;
}
.m-t-md {
  margin-top: 15 * $px;
}
.m-t-lg {
  margin-top: 30 * $px;
}

.m-b-xs {
  margin-bottom: 5 * $px;
}
.m-b-sm {
  margin-bottom: 10 * $px;
}
.m-b-md {
  margin-bottom: 15 * $px;
}
.m-b-lg {
  margin-bottom: 30 * $px;
}

[hidden],
.hidden {
  display: none !important;
}

.cdk-overlay-container {
  z-index: 1001;
}
