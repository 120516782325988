@use 'src/styles/_var' as vars;

$themes: (
  'dark': (
    // Primary
    bg: vars.$bg-dark,
    primary-grey-100: vars.$primary-grey-100-dark,
    primary-grey-80: vars.$primary-grey-80-dark,
    primary-grey-60: vars.$primary-grey-60-dark,
    primary-grey-40: vars.$primary-grey-40-dark,
    primary-grey-20: vars.$primary-grey-20-dark,
    // Secondary-dark,
    primary-grey-10: vars.$primary-grey-10-dark,
    primary-grey-4: vars.$primary-grey-4-dark,
    primary-grey-8: vars.$primary-grey-8-dark,
    // Accents-dark,
    accent-blue-100: vars.$accent-blue-100-dark,
    accent-blue-120: vars.$accent-blue-120-dark,
    accent-blue-80: vars.$accent-blue-80-dark,
    accent-blue-40: vars.$accent-blue-40-dark,
    accent-blue-20: vars.$accent-blue-20-dark,
    accent-blue-10: vars.$accent-blue-10-dark,
    // Accent pink
    accent-pink-100: vars.$accent-pink-100-dark,
    accent-pink-80: vars.$accent-pink-80-dark,
    accent-pink-60: vars.$accent-pink-60-dark,
    accent-pink-40: vars.$accent-pink-40-dark,
    accent-pink-20: vars.$accent-pink-20-dark,
    // Borders-dark,
    borders-white-100: vars.$borders-white-100-dark,
    borders-white-8: vars.$borders-white-8-dark,
    borders-white-4: vars.$borders-white-4-dark,
    // Content-dark,
    content-white-100: vars.$content-white-100-dark,
    content-white-80: vars.$content-white-80-dark,
    content-white-60: vars.$content-white-60-dark,
    content-white-40: vars.$content-white-40-dark,
    content-white-20: vars.$content-white-20-dark,
    content-white-10: vars.$content-white-10-dark,
    // Additional Colors-dark,
    // Orange:
    additional-orange-100: vars.$additional-orange-100-dark,
    additional-orange-80: vars.$additional-orange-80-dark,
    additional-orange-60: vars.$additional-orange-60-dark,
    additional-orange-40: vars.$additional-orange-40-dark,
    additional-orange-20: vars.$additional-orange-20-dark,
    additional-orange-10: vars.$additional-orange-10-dark,
    // Green:
    additional-green-100: vars.$additional-green-100-dark,
    additional-green-80: vars.$additional-green-80-dark,
    additional-green-60: vars.$additional-green-60-dark,
    additional-green-40: vars.$additional-green-40-dark,
    additional-green-20: vars.$additional-green-20-dark,
    additional-green-10: vars.$additional-green-10-dark,
    // Yellow:
    additional-yellow-100: vars.$additional-yellow-100-dark,
    additional-yellow-80: vars.$additional-yellow-80-dark,
    additional-yellow-60: vars.$additional-yellow-60-dark,
    additional-yellow-40: vars.$additional-yellow-40-dark,
    additional-yellow-20: vars.$additional-yellow-20-dark,
    additional-yellow-10: vars.$additional-yellow-10-dark,
    // Red:
    additional-red-100: vars.$additional-red-100-dark,
    additional-red-80: vars.$additional-red-80-dark,
    additional-red-60: vars.$additional-red-60-dark,
    additional-red-40: vars.$additional-red-40-dark,
    additional-red-20: vars.$additional-red-20-dark,
    additional-red-10: vars.$additional-red-10-dark,
    // Marine:
    additional-marine-100: vars.$additional-marine-100-dark,
    additional-marine-80: vars.$additional-marine-80-dark,
    additional-marine-60: vars.$additional-marine-60-dark,
    additional-marine-40: vars.$additional-marine-40-dark,
    additional-marine-20: vars.$additional-marine-20-dark,
    additional-marine-10: vars.$additional-marine-10-dark,
    // Purple
    additional-purple-100: vars.$additional-purple-100-dark,
    additional-purple-80: vars.$additional-purple-80-dark,
    additional-purple-60: vars.$additional-purple-60-dark,
    additional-purple-40: vars.$additional-purple-40-dark,
    additional-purple-20: vars.$additional-purple-20-dark,
    additional-purple-10: vars.$additional-purple-10-dark,
    //scrollbar
    scrollbar-color-0: vars.$scrollbar-color-0-dark,

    //secondary
    secondary-grey-10: vars.$secondary-grey-10-dark,
    secondary-grey-8: vars.$secondary-grey-8-dark,
    secondary-grey-4: vars.$secondary-grey-4-dark,

    // accent gradient
    accent-gradient: vars.$accent-gradient-dark,

    // Logo:
    logo: vars.$logo-dark,
    logo_monogram: vars.$logo_monogram_dark,
    additional-yellow-maintenance: vars.$additional-yellow-maintenance,

    // new mapping from figma
    colors-background-secondary: vars.$color-neutrals-800,
    colors-background-tertiary: vars.$color-neutrals-700,
    colors-button-filled-text-default: vars.$color-neutrals-800,
    colors-button-filled-bg-hover-pressed: vars.$color-neutrals-300,
    colors-message-error: vars.$color-accents-red-100,
    colors-stroke-default: vars.$color-neutrals-700,
    colors-stroke-hover-active: vars.$color-neutrals-600,
    colors-text-secondary: vars.$color-neutrals-400,

  ),
  'light': (
    bg: vars.$bg-light,
    // Primary-light
    primary-grey-100: vars.$primary-grey-100-light,
    primary-grey-80: vars.$primary-grey-80-light,
    primary-grey-60: vars.$primary-grey-60-light,
    primary-grey-40: vars.$primary-grey-40-light,
    primary-grey-20: vars.$primary-grey-20-light,
    // Secondary-light,
    primary-grey-10: vars.$primary-grey-10-light,
    primary-grey-4: vars.$primary-grey-4-light,
    primary-grey-8: vars.$primary-grey-8-light,
    // Accents-light,
    accent-blue-100: vars.$accent-blue-100-light,
    accent-blue-120: vars.$accent-blue-120-light,
    accent-blue-80: vars.$accent-blue-80-light,
    accent-blue-40: vars.$accent-blue-40-light,
    accent-blue-20: vars.$accent-blue-20-light,
    accent-blue-10: vars.$accent-blue-10-light,
    // Accent pink
    accent-pink-100: vars.$accent-pink-100-light,
    accent-pink-80: vars.$accent-pink-80-light,
    accent-pink-60: vars.$accent-pink-60-light,
    accent-pink-40: vars.$accent-pink-40-light,
    accent-pink-20: vars.$accent-pink-20-light,
    // Borders-light,
    borders-white-100: vars.$borders-white-100-light,
    borders-white-8: vars.$borders-white-8-light,
    borders-white-4: vars.$borders-white-4-light,
    // Content-light,
    content-white-100: vars.$content-white-100-light,
    content-white-80: vars.$content-white-80-light,
    content-white-60: vars.$content-white-60-light,
    content-white-40: vars.$content-white-40-light,
    content-white-20: vars.$content-white-20-light,
    content-white-10: vars.$content-white-10-light,
    // Additional Colors-light,
    // Orange:
    additional-orange-100: vars.$additional-orange-100-light,
    additional-orange-80: vars.$additional-orange-80-light,
    additional-orange-60: vars.$additional-orange-60-light,
    additional-orange-40: vars.$additional-orange-40-light,
    additional-orange-20: vars.$additional-orange-20-light,
    additional-orange-10: vars.$additional-orange-10-light,
    // Green:
    additional-green-100: vars.$additional-green-100-light,
    additional-green-80: vars.$additional-green-80-light,
    additional-green-60: vars.$additional-green-60-light,
    additional-green-40: vars.$additional-green-40-light,
    additional-green-20: vars.$additional-green-20-light,
    additional-green-10: vars.$additional-green-10-light,
    // Yellow:
    additional-yellow-100: vars.$additional-yellow-100-light,
    additional-yellow-80: vars.$additional-yellow-80-light,
    additional-yellow-60: vars.$additional-yellow-60-light,
    additional-yellow-40: vars.$additional-yellow-40-light,
    additional-yellow-20: vars.$additional-yellow-20-light,
    additional-yellow-10: vars.$additional-yellow-10-light,
    // Red:
    additional-red-100: vars.$additional-red-100-light,
    additional-red-80: vars.$additional-red-80-light,
    additional-red-60: vars.$additional-red-60-light,
    additional-red-40: vars.$additional-red-40-light,
    additional-red-20: vars.$additional-red-20-light,
    additional-red-10: vars.$additional-red-10-light,
    // Marine:
    additional-marine-100: vars.$additional-marine-100-light,
    additional-marine-80: vars.$additional-marine-80-light,
    additional-marine-60: vars.$additional-marine-60-light,
    additional-marine-40: vars.$additional-marine-40-light,
    additional-marine-20: vars.$additional-marine-20-light,
    additional-marine-10: vars.$additional-marine-10-light,
    // Purple
    additional-purple-100: vars.$additional-purple-100-light,
    additional-purple-80: vars.$additional-purple-80-light,
    additional-purple-60: vars.$additional-purple-60-light,
    additional-purple-40: vars.$additional-purple-40-light,
    additional-purple-20: vars.$additional-purple-20-light,
    additional-purple-10: vars.$additional-purple-10-light,
    //scrollbar
    scrollbar-color-0: vars.$scrollbar-color-0-light,

    //secondary
    secondary-grey-10: vars.$secondary-grey-10-light,
    secondary-grey-8: vars.$secondary-grey-8-light,
    secondary-grey-4: vars.$secondary-grey-4-light,

    // accent gradient
    accent-gradient: vars.$accent-gradient-light,

    // Logo:
    logo: vars.$logo-light,
    logo_monogram: vars.$logo_monogram_light,
    additional-yellow-maintenance: vars.$additional-yellow-maintenance,
  ),
);

@each $theme-name, $theme-colors in $themes {
  .theme.#{$theme-name} {
    // define a css variable for each theme color
    @each $name, $color in $theme-colors {
      --#{$name}: #{$color};
    }
  }
}