@use 'var' as vars;
@import '@angular/cdk/overlay-prebuilt.css';

.cdk-overlay-backdrop.bebop-modal-backdrop.cdk-overlay-backdrop-showing,
.bebop-modal-backdrop {
  background: var(--primary);
  opacity: 0.8;
}

.bebop-modal-backdrop-transparent {
  background: transparent;
}

.cdk-global-scrollblock {
  height: auto;
}
