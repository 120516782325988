@use 'sass:math';
@use 'sass:color';

// Get one pixel size in rem's so it makes easier to convert pixel based sizes into relative ones
// 16 is the default value relative to wich we can scale things up and down.
$pixel: math.div(1, 16);
$px: $pixel * 1rem;

// Set root font size so `em` units will be calculated relative to that value.
$root-font-size: 16; // same as usual browsers default value
$root-font-size-px: $root-font-size * 1 * $px;

/** Dark Theme colors */
// Primary
$bg-dark: #040405;
$primary-grey-100-dark: #0c0c0f;
$primary-grey-80-dark: #1a1a1f;
$primary-grey-60-dark: #2b2b33;
$primary-grey-40-dark: #5a5a68;
$primary-grey-20-dark: #7f7f8c;
// Secondary
$primary-grey-10-dark: rgba(34, 34, 41, 0.84);
$primary-grey-4-dark: rgba(43, 43, 51, 0.32);
$primary-grey-8-dark: rgba(12, 12, 15, 0.32);

// Accents
$accent-blue-100-dark: rgba(45, 93, 224, 1);
$accent-blue-120-dark: rgba(69, 116, 245, 1);
$accent-blue-80-dark: rgba(22, 65, 184, 1);
$accent-blue-40-dark: rgba(45, 93, 224, 0.4);
$accent-blue-20-dark: rgba(45, 93, 224, 0.2);
$accent-blue-10-dark: rgba(45, 93, 224, 0.1);

// accent pink
$accent-pink-100-dark: rgba(241, 60, 86, 1);
$accent-pink-80-dark: rgba(241, 60, 86, 0.8);
$accent-pink-60-dark: rgba(241, 60, 86, 0.6);
$accent-pink-40-dark: rgba(241, 60, 86, 0.4);
$accent-pink-20-dark: rgba(241, 60, 86, 0.2);

// Borders
$borders-white-100-dark: linear-gradient(164.35deg, rgba(255, 255, 255, 0.08) 0.75%, rgba(255, 255, 255, 0.04) 97.46%);
$borders-white-8-dark: rgba(255, 255, 255, 0.08);
$borders-white-4-dark: rgba(255, 255, 255, 0.04);

// Content
$content-white-100-dark: rgba(255, 255, 255, 1);
$content-white-80-dark: rgba(255, 255, 255, 0.8);
$content-white-60-dark: rgba(255, 255, 255, 0.6);
$content-white-40-dark: rgba(255, 255, 255, 0.4);
$content-white-20-dark: rgba(255, 255, 255, 0.2);
$content-white-10-dark: rgba(255, 255, 255, 0.1);

// Additional Colors

// Orange
$additional-orange-100-dark: rgba(224, 133, 82, 1);
$additional-orange-80-dark: rgba(224, 133, 82, 0.8);
$additional-orange-60-dark: rgba(224, 133, 82, 0.6);
$additional-orange-40-dark: rgba(224, 133, 82, 0.4);
$additional-orange-20-dark: rgba(224, 133, 82, 0.2);
$additional-orange-10-dark: rgba(224, 133, 82, 0.1);

// Green
$additional-green-100-dark: rgba(50, 186, 120, 1);
$additional-green-80-dark: rgba(50, 186, 120, 0.8);
$additional-green-60-dark: rgba(50, 186, 120, 0.6);
$additional-green-40-dark: rgba(50, 186, 120, 0.4);
$additional-green-20-dark: rgba(50, 186, 120, 0.2);
$additional-green-10-dark: rgba(50, 186, 120, 0.1);

// Yellow
$additional-yellow-100-dark: rgba(255, 193, 76, 1);
$additional-yellow-80-dark: rgba(255, 193, 76, 0.8);
$additional-yellow-60-dark: rgba(255, 193, 76, 0.6);
$additional-yellow-40-dark: rgba(255, 193, 76, 0.4);
$additional-yellow-20-dark: rgba(255, 193, 76, 0.2);
$additional-yellow-10-dark: rgba(255, 193, 76, 0.1);

$additional-yellow-maintenance: #e7cb86;

// Red
$additional-red-100-dark: rgba(241, 52, 75, 1);
$additional-red-80-dark: rgba(241, 52, 75, 0.8);
$additional-red-60-dark: rgba(241, 52, 75, 0.6);
$additional-red-40-dark: rgba(241, 52, 75, 0.4);
$additional-red-20-dark: rgba(241, 52, 75, 0.2);
$additional-red-10-dark: rgba(241, 52, 75, 0.1);

// Marine
$additional-marine-100-dark: rgba(46, 186, 246, 1);
$additional-marine-80-dark: rgba(46, 186, 246, 0.8);
$additional-marine-60-dark: rgba(46, 186, 246, 0.6);
$additional-marine-40-dark: rgba(46, 186, 246, 0.4);
$additional-marine-20-dark: rgba(46, 186, 246, 0.2);
$additional-marine-10-dark: rgba(46, 186, 246, 0.1);

// purple
$additional-purple-100-dark: rgba(99, 99, 224, 1);
$additional-purple-80-dark: rgba(99, 99, 224, 0.8);
$additional-purple-60-dark: rgba(99, 99, 224, 0.6);
$additional-purple-40-dark: rgba(99, 99, 224, 0.4);
$additional-purple-20-dark: rgba(99, 99, 224, 0.2);
$additional-purple-10-dark: rgba(99, 99, 224, 0.1);

$secondary-grey-10-dark: rgba(34, 34, 41, 0.84);
$secondary-grey-8-dark: rgba(12, 12, 15, 0.32);
$secondary-grey-4-dark: rgba(43, 43, 51, 0.32);

$accent-gradient-dark: linear-gradient(92.9deg, #3d70ff 23.52%, #cf38d2 52.31%, #ee225e 81.62%);

$scrollbar-color-0-dark: #474a54;

$logo_dark: url('/assets/img/bebop_core_logo_dark.png');
$login_background_dark: url('/assets/img/backgrounds/login_background.png');
$desktop_background_dark: url('/assets/img/backgrounds/desktop_background.png');
// $web_background_dark: url('/assets/img/backgrounds/web_background.png');

$app_updating_dark: url('/assets/img/updater/updating.gif');
$app_update_error_dark: url('/assets/img/updater/update_error.gif');
$logo_monogram_dark: url('/assets/img/cree8_dark.svg');

$dotted_loader_dark: url('/assets/img/loader/loader.gif');
/** -------------------- ------------------

/** Light Theme Colors */
// Primary
$bg-light: #ffffff;
$primary-grey-100-light: #0c0c0f;
$primary-grey-80-light: #1a1a1f;
$primary-grey-60-light: #2b2b33;
$primary-grey-40-light: #5a5a68;
$primary-grey-20-light: #7f7f8c;

// Secondary
$primary-grey-10-light: rgba(34, 34, 41, 0.84);
$primary-grey-4-light: rgba(43, 43, 51, 0.32);
$primary-grey-8-light: rgba(12, 12, 15, 0.32);

// Accents
$accent-blue-100-light: rgba(45, 93, 224, 1);
$accent-blue-120-light: rgba(69, 116, 245, 1);
$accent-blue-80-light: rgba(22, 65, 184, 1);
$accent-blue-40-light: rgba(45, 93, 224, 0.4);
$accent-blue-20-light: rgba(45, 93, 224, 0.2);
$accent-blue-10-light: rgba(45, 93, 224, 0.1);

// accent pink
$accent-pink-100-light: rgba(241, 60, 86, 1);
$accent-pink-80-light: rgba(241, 60, 86, 0.8);
$accent-pink-60-light: rgba(241, 60, 86, 0.6);
$accent-pink-40-light: rgba(241, 60, 86, 0.4);
$accent-pink-20-light: rgba(241, 60, 86, 0.2);

// Borders
$borders-white-100-light: linear-gradient(164.35deg, rgba(255, 255, 255, 0.08) 0.75%, rgba(255, 255, 255, 0.04) 97.46%);
$borders-white-8-light: rgba(255, 255, 255, 0.08);
$borders-white-4-light: rgba(255, 255, 255, 0.04);

// Content
$content-white-100-light: rgba(255, 255, 255, 1);
$content-white-80-light: rgba(255, 255, 255, 0.8);
$content-white-60-light: rgba(255, 255, 255, 0.6);
$content-white-40-light: rgba(255, 255, 255, 0.4);
$content-white-20-light: rgba(255, 255, 255, 0.2);
$content-white-10-light: rgba(255, 255, 255, 0.1);

// Additional Colors
// Orange
$additional-orange-100-light: rgba(224, 133, 82, 1);
$additional-orange-80-light: rgba(224, 133, 82, 0.8);
$additional-orange-60-light: rgba(224, 133, 82, 0.6);
$additional-orange-40-light: rgba(224, 133, 82, 0.4);
$additional-orange-20-light: rgba(224, 133, 82, 0.2);
$additional-orange-10-light: rgba(224, 133, 82, 0.1);

// Green
$additional-green-100-light: rgba(50, 186, 120, 1);
$additional-green-80-light: rgba(50, 186, 120, 0.8);
$additional-green-60-light: rgba(50, 186, 120, 0.6);
$additional-green-40-light: rgba(50, 186, 120, 0.4);
$additional-green-20-light: rgba(50, 186, 120, 0.2);
$additional-green-10-light: rgba(50, 186, 120, 0.1);

// Yellow
$additional-yellow-100-light: rgba(255, 193, 76, 1);
$additional-yellow-80-light: rgba(255, 193, 76, 0.8);
$additional-yellow-60-light: rgba(255, 193, 76, 0.6);
$additional-yellow-40-light: rgba(255, 193, 76, 0.4);
$additional-yellow-20-light: rgba(255, 193, 76, 0.2);
$additional-yellow-10-light: rgba(255, 193, 76, 0.1);

// Red
$additional-red-100-light: rgba(241, 52, 75, 1);
$additional-red-80-light: rgba(241, 52, 75, 0.8);
$additional-red-60-light: rgba(241, 52, 75, 0.6);
$additional-red-40-light: rgba(241, 52, 75, 0.4);
$additional-red-20-light: rgba(241, 52, 75, 0.2);
$additional-red-10-light: rgba(241, 52, 75, 0.1);

// Marine
$additional-marine-100-light: rgba(46, 186, 246, 1);
$additional-marine-80-light: rgba(46, 186, 246, 0.8);
$additional-marine-60-light: rgba(46, 186, 246, 0.6);
$additional-marine-40-light: rgba(46, 186, 246, 0.4);
$additional-marine-20-light: rgba(46, 186, 246, 0.2);
$additional-marine-10-light: rgba(46, 186, 246, 0.1);

// purple
$additional-purple-100-light: rgba(99, 99, 224, 1);
$additional-purple-80-light: rgba(99, 99, 224, 0.8);
$additional-purple-60-light: rgba(99, 99, 224, 0.6);
$additional-purple-40-light: rgba(99, 99, 224, 0.4);
$additional-purple-20-light: rgba(99, 99, 224, 0.2);
$additional-purple-10-light: rgba(99, 99, 224, 0.1);

$secondary-grey-10-light: rgba(34, 34, 41, 0.84);
$secondary-grey-8-light: rgba(12, 12, 15, 0.32);
$secondary-grey-4-light: rgba(43, 43, 51, 0.32);

$accent-gradient-light: linear-gradient(92.9deg, #3d70ff 23.52%, #cf38d2 52.31%, #ee225e 81.62%);

$scrollbar-color-0-light: #474a54;

$logo_light: url('/assets/img/bebop_core_logo_light.png');
$login_background_light: url('/assets/img/backgrounds/login_background.png');
$desktop_background_light: url('/assets/img/backgrounds/desktop_background.png');
// $web_background_light: url('/assets/img/backgrounds/web_background.png');
$app_updating_light: url('/assets/img/updater/updating.gif');
$app_update_error_light: url('/assets/img/updater/update_error.gif');
$logo_monogram_light: url('/assets/img/cree8_light.svg');
$dotted_loader_light: url('/assets/img/loader/loader.gif');

$particleColors: #c21c59, #ed1454, #fc3f3f, #0268b5, #0538dd, #0a33b7, #00006b;

$toolbar_height: 30px;
$header_height: 56px; // hardcoded in js too for modals top property
$toolbar_header_height: ($toolbar_height + $header_height);


// new mapping color reference in figma: https://www.figma.com/design/3gHcMNySnAU44XV8gEdDIf/CREE8-Design-System?node-id=226-2781&t=4gweYZ0MPSDyGbTV-0
$color-neutrals-000: #ffffff;
$color-neutrals-100: #f5f5f5;
$color-neutrals-200: #eaecee;
$color-neutrals-300: #ced4da;
$color-neutrals-400: #adb5bd;
$color-neutrals-500: #6c757d;
$color-neutrals-600: #494e55;
$color-neutrals-700: #25262c;
$color-neutrals-800: #1d1d23;
$color-neutrals-900: #14141a;

$color-gradient-dark-100: #1d1d23;
$color-gradient-dark-000: #1d1d23;

$color-gradient-light-100: #f5f5f5;
$color-gradient-light-000: #f5f5f5;

$color-accents-red-100: #ff5c4d;
$color-accents-yellow-100: #ffd700;
$color-accents-blue-100: #64b5f6;
$color-accents-green-100: #81c784;

$color-accents-purple-100: #e6d7ff;
$color-accents-purple-200: #d2bafd;
$color-accents-purple-300: #a97ffa;
$color-accents-purple-400: #905ef8;
$color-accents-purple-500: #6610f5;
$color-accents-purple-600: #5000c8;
$color-accents-purple-700: #3c0096;
$color-accents-purple-800: #290064;
$color-accents-purple-900: #150032;

$color-brand-azure-100: #e6f3ff;
$color-brand-azure-300: #66bfff;
$color-brand-azure-500: #007fff;
$color-brand-azure-700: #0066cc;
$color-brand-azure-900: #004c99;

$color-brand-rose-100: #ffe6f3;
$color-brand-rose-300: #ff80b3;
$color-brand-rose-500: #ff007f;
$color-brand-rose-700: #cc0066;
$color-brand-rose-900: #99004c;

$color-brand-gold-100: #fff8cc;
$color-brand-gold-300: #ffe066;
$color-brand-gold-500: #f5df4d;
$color-brand-gold-700: #ccb842;
$color-brand-gold-900: #998d33;

$color-brand-lime-100: #e6ffe6;
$color-brand-lime-300: #80ff80;
$color-brand-lime-500: #39ff14;
$color-brand-lime-700: #2eb312;
$color-brand-lime-900: #227f0d;
