@use '../variables' as vars;
 $px: vars.$px;

 
@mixin tooltip-arrow($position) {
    &::before {
      content: '';
      position: absolute;
      border-style: solid;
      border-width: 5 * $px;
      color:var(--tertiary);
  
      @if $position == top {
        bottom: -10 * $px;
        left: 50%;
        transform: translateX(-50%);
        border-color: var(--tertiary) transparent transparent transparent;
      }
  
      @if $position == bottom {
        top: -10 * $px;
        left: 50%;
        transform: translateX(-50%);
        border-color: transparent transparent var(--tertiary) transparent;
      }
  
      @if $position == left {
        right: -10 * $px;
        top: 50%;
        transform: translateY(-50%);
        border-color: transparent transparent transparent var(--tertiary);
      }
  
      @if $position == right {
        left: -10 * $px;
        top: 50%;
        transform: translateY(-50%);
        border-color: transparent var(--tertiary) transparent transparent;
      }
    }
  }

 .cree8-tooltip {
     position: absolute;
     background-color: var(--tertiary);
     padding: 4 * $px 8 * $px;
     border-radius: 8 * $px;
     white-space: nowrap;
     z-index: 1000;
     opacity: 0;

     &.bottom {
        @include tooltip-arrow(bottom);
      }
    
      &.top {
        @include tooltip-arrow(top);
      }
    
      &.left {
        @include tooltip-arrow(left);
      }
    
      &.right {
        @include tooltip-arrow(right);
      }

      &.show {
        opacity: 1;
      }
}

